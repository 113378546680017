<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('log_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "登录IP",
            key: "ip",
          },
          {
            title: "登录名",
            align: "center",
            key: "logname",
          },
          {
            title: "登录账号",
            align: "center",
            key: "succeed",
          },
          {
            title: "登录时间",
            align: "center",
            key: "createtime",
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "登录账号",
              component: "input",
              field: "succeed",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      search_data: {},
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA("log_check")) return;
      this.table.loading = true;
      this.$post(this.$api.LOG_MANAGE.LOGIN, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>